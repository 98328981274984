<template>
  <div class="account-container">
    <!-- <a href="https://www.mockplus.cn/?home=1" class="left-logo">
    <img src="https://www.yaoguai-oss-pub.cn/image/image_cache/2022/07/02/6a78903184d0f98373363c29227a5b87.jpg?x-oss-process=image/resize,h_100" alt="">
</a> -->

    <div class="right-container">
      <!--                微信登录-->
      <div class="sign-list" id="sign-weixin" style="display: block">
        <div class="weixin-wrap">
          <div class="tops">请使用微信扫码登录</div>
          <div class="ewm-wrap">
            <div class="pic">
              <img :src="loginCode" alt="" id="er-code-img" class="" />
            </div>
            <div class="expired" id="er-code-expired">
              二维码已过期
              <span>刷新重试</span>
            </div>
          </div>
          <div class="other-text">
            扫码表示同意《<a class="red">使用协议</a>》及《
            <a class="red">隐私政策</a>》
          </div>
        </div>
      </div>
      <!----------------- over ----------------->
    </div>
  </div>
</template>


<script type="text/javascript" src="https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js"></script>

<script>
import ajax from "../../common/js/axios.js";
//import ajaxOSS from "../common/js/axiososs.js";

import axios from "axios";
import "./account.css";
//import affix from 'easy-affix'

export default {
  metaInfo: {
    title: "图鲁班-PDF转word",
    meta: [
      {
        name: "keywords",
        content: "免费pdf转word,pdf转word,pdf转word在线,图鲁班",
      },
      {
        name: "description",
        content:
          "图鲁班|免费的pdf转word在线工具|免登录、免下载软件、免费、格式无差别转换、转换效果好、页面简约易操作",
      },
    ],
  },
  data() {
    return {
      loginCode: "https://www.yaoguai-oss-pub.cn/temp/2022/07/06/1657120202113.png",
      scene: "",
      intervalID:''
      
    };
  },
  onload() {},
  mounted() {
    let _this = this;
    ajax.post("getLoginCode", {}).then((response) => {
      _this.loginCode = response.meta.loginCode;
      _this.scene = response.meta.scene;
    });
    this.getLoginStatus();

    
  },
  destroyed(){
  clearInterval(this.intervalID);
},
  methods: {
    getLoginStatus() {
      let _this = this;
     
 
  this.intervalID = setInterval(function () {
       console.log("轮训");

 if (this.scene != "") {
     ajax
          .post("checkLoginCode", {
            scene: _this.scene,
          })
          .then((response) => {
            console.log("res..." + JSON.stringify(response));
            if (response.code == 200) {
              console.log("登录成功");
                  
                 _this.$store.dispatch("setToken", response.meta.authToken).then(() => {
                             
                 _this.$store.dispatch("setUserInfo",JSON.stringify(response.meta)).then(()=>{
                  debugger;
                    _this.$router.go(-1);
                 });
               
                 
                 
                console.log("de");
          //that.$router.push({path: "/"})
        })
               clearInterval(intervalID);
            
              return response.meta;
            } else if (response.code == -45) {
              return response;
            } else if (response.code == 203) {
              return;
            } else if (response.code == 205) {
              return;
            } else {
              return response;
            }
          })
          .catch((error) => {
            return error;
          });


        }
      
       
    }, 2000);





       
     
    },
  },
};
</script>


<style scoped>
/* .question {
  font-size: 25px;
  float: left;
  color: rgb(221, 22, 55);
} */

.logoTitle {
  font-size: 30px;
}
.el-row {
  margin-bottom: 20px;
}
.bottom-affix {
  padding-right: 20px;
  font-family: "Helvetica Neue";
}
.bootImage {
  width: 130px;
  height: 130px;
}

/deep/.el-upload-dragger {
  width: 100% !important;
  height: 50vh;
}
/deep/.el-upload {
  height: 100% !important;
  display: block;
}
/deep/.el-icon-upload {
  font-size: 150px;
  padding-top: 50px;
}
/deep/.el-upload__text {
  line-height: 0px !important;
  font-size: 20px;
}
.resultheader {
  float: left;
}
.about1 {
  padding-top: 10px;
}
.about {
  height: 50px;
}
.tag-group__title {
  padding: auto;
}
.tag-group {
  padding-top: 20px;
}
.el-tag {
  width: 100px;
}

.titltext {
  font-size: 20px;
}
</style>
