<template>
  <div>

    <div>

      <el-divider content-position="center">网络链接和本地上传二选一</el-divider>
        <el-input v-model="imageUrl" style="width:50%" placeholder="请输入内容">
          <el-button  @click="uploadurl" slot="append" icon="el-icon-upload2"></el-button>
        </el-input>

      <el-upload
        ref="upload1"
        action=""
        class="avatar-uploader"
        :before-upload="beforeUpload"
        :http-request="uploadFile"
        accept=".png,.jpg,.jpeg"
        :multiple="false"
        :limit="10"
        :show-file-list="false"
        :auto-upload="true"
        v-loading="showLoading"
      >
        <img v-if="imageUrl" id="background" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"> </i>
      </el-upload>
    </div>

    <h1 v-if="colorsd.length>0">十六进制颜色：</h1>
    <ul class="show">
      <li class="copytxt" @click="copy(color)" v-for="(color, index) in colorsd" :key="index" :style="{ background: color }">
        
        <el-link :underline="false" icon="el-icon-copy-document">{{ color }}</el-link>
      </li>
    </ul>

    <el-dialog
      title="图片剪裁"
      :visible.sync="dialogVisiblex"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      append-to-body
      width="1000px"
    >
      <div class="cropper-content">
        <div class="cropper" style="text-align:center">
          <vueCropper
            ref="cropper"
            :img="option.img"
            :outputSize="option.outputSize"
            :outputType="option.outputType"
            :info="option.info"
            :canScale="option.canScale"
            :autoCrop="option.autoCrop"
            :autoCropWidth="option.autoCropWidth"
            :autoCropHeight="option.autoCropHeight"
            :fixedBox="option.fixedBox"
            :fixed="option.fixed"
            :fixedNumber="option.fixedNumber"
            :canMove="option.canMove"
            :canMoveBox="option.canMoveBox"
            :original="option.original"
            :centerBox="option.centerBox"
            :infoTrue="option.infoTrue"
            :full="option.full"
            :enlarge="option.enlarge"
            :mode="option.mode"
          >
          </vueCropper>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblex = false">取消</el-button>
        <el-button type="primary" @click="finish" :loading="loading"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>


<script >
import ColorThief from "colorthief";

import ajax from "../../common/js/axios.js";

import SparkMD5 from "spark-md5";
import axios from "axios";
import { Loading } from "element-ui";


export default {
  components: {
   
  },
  props: {},
  data() {
    return {
      colorsd:[],
      showLoading:false,
      imageUrl:'',
      dialogVisiblex: false,
      loading: false,
      option: {
        img: '', // 裁剪图片的地址 url 地址, base64, blob
        outputSize: 1, // 裁剪生成图片的质量
        outputType: 'jpeg', // 裁剪生成图片的格式 jpeg, png, webp
        info: true, // 裁剪框的大小信息
        canScale: false, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 100, // 默认生成截图框宽度
        autoCropHeight: 100, // 默认生成截图框高度
        fixedBox: false, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [1, 1], // 截图框的宽高比例 [ 宽度 , 高度 ]
        canMove: true, // 上传图片是否可以移动
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
        full: true, // 是否输出原图比例的截图
        enlarge: '1', // 图片根据截图框输出比例倍数
        mode: 'contain' // 图片默认渲染方式 contain , cover, 100px, 100% auto
      },
      unimgurl: '',
      success: () => {} // 回调方法
    }
  },
  computed: {},
  watch: {},
  created() {

  },
  mounted() {},
  activated() {},
  methods: {
    uploadurl:function(){
      if(this.imageUrl == ''){
        this.$notify({
              title: "消息",
              message: "先上传图片在识别哦",
              type: "warning",
            });
        return;
      }
      
      this.option.img = this.imageUrl;
      this.dialogVisiblex= true;
    },
    uploadFiles: function (md5, file) {
      this.md5 = md5;
      return new Promise(function (resolve, reject) {
        ajax.get("preImage?md5=" + md5+"&scene=5", {}).then((response) => {
          console.log(response);
          if (response.code == 201) {
            resolve(response.meta);
            return response.meta;
          }
          if (response.code == -45) {
            reject(response);
            return response;
          }
          let responData = response.meta.bean;
          //  var form = {};
          //
          // form.policy = responData.policy;
          // form.OSSAccessKeyId = responData.accessid;
          // form.signature = responData.signature;
          // form.success_action_status = "200";
          // form.key = responData.ossKey;
          // form.fileKey = responData.fileKey;
          // form.callback = responData.base64CallbackBody;

          let formData1 = new window.FormData();

          formData1.append("key", responData.ossKey);
          formData1.append("policy", responData.policy);
          formData1.append("OSSAccessKeyId", responData.accessid);
          formData1.append("success_action_status", "200");
          formData1.append("callback", responData.base64CallbackBody);
          formData1.append("signature", responData.signature);
          formData1.append("file", file);
          axios({
            url: "https://" + responData.host,
            method: "post",
            data: formData1,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {
            console.log("res.." + JSON.stringify(res));
            if (res.data.Status == "OK") {
              let intervalID = setInterval(function () {
                console.log("轮训");
                ajax
                  .post("getImageStatus", {
                    md5: md5,
                  })
                  .then((response) => {
                    console.log("res..." + JSON.stringify(response));
                    if (response.code == 200) {
                      clearInterval(intervalID);
                      resolve(response.meta);
                      return response.meta;
                    } else if (response.code == -45) {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    } else if (response.code == 203) {
                      //clearInterval(intervalID)
                      //reject(response);
                      //return response;
                      console.log("kjk");
                      /* uni.showToast({
																	title: '检测到上传文件共'+response.meta.pages+"页",请您耐心等待,
																	icon: 'none'
																}); */
                      return;
                    } else if (response.code == 205) {
                      return;
                    } else {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    }
                  })
                  .catch((error) => {
                    clearInterval(intervalID);
                    reject(error);
                    return error;
                  });
              }, 2000);
            } else {
              console.log("oss出错。。。");
              reject(res);
              return res;
            }
          });
        });
      });
    },
    beforeUpload: function (file) {
      this.showLoading = true;
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        this.$notify.info({
          title: "提示",
          message: "文件不能超过10m",
        });
        return;
      }

      this.handlePrepareUpload(file).then((res) => {
        this.uploadFiles(res, file)
          .then((res) => {
            //loadingInstance.close();
            console.log(res);
            //this.listResult.push(res);
            this.showLoading = false;
            this.imageUrl = res.url;
            this.uploadurl();
          })
          .catch((err) => {
            console.log(err);
            this.showLoading = false;
            this.$notify({
              title: "消息",
              message: "转换错误，请刷新页面重试",
              type: "warning",
            });
          });
      });

    },
    handlePrepareUpload: function (file) {
      return new Promise(function (resolve) {
        const fileSize = file.size; // 文件大小
        const chunkSize = 1024 * 1024 * 10; // 切片的大小
        const chunks = Math.ceil(fileSize / chunkSize); // 获取切片个数
        const fileReader = new FileReader();
        const spark = new SparkMD5.ArrayBuffer();
        const bolbSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        let currentChunk = 0;

        fileReader.onload = (e) => {
          const res = e.target.result;
          spark.append(res);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
            console.log(
              `第${currentChunk}分片解析完成, 开始第${currentChunk + 1}分片解析`
            );
          } else {
            const md5 = spark.end() + ".jpg";

            //alert(md5)
            resolve(md5);
            return md5;
          }
        };

        const loadNext = () => {
          const start = currentChunk * chunkSize;
          const end =
            start + chunkSize > file.size ? file.size : start + chunkSize;
          fileReader.readAsArrayBuffer(bolbSlice.call(file, start, end));
        };
        loadNext();
      });
    },
    getColor(){
      let colorThief = new ColorThief();
    const img = document.querySelector('#background');
    let _this = this;
   
    img.addEventListener('load', function() {
     
      _this.colorsd = [];
       _this.colors = colorThief.getPalette(img);
      
            for(let str of _this.colors){
             
              _this.transformHEXToGRB(str)
            }
            console.log(".."+_this.colorsd.toString());
   
      });
    
    },
  
    copy(txt) {
      const cInput = document.createElement("input");
      cInput.value = txt;
      document.body.appendChild(cInput);
      cInput.select(); //

      document.execCommand("Copy");

      this.$message({
        message: "复制成功  "+txt,
        type: "success",
        duration:1000
      });
    },
    transformHEXToGRB(rgb) {
      var r = parseInt(rgb[0]);
        var g = parseInt(rgb[1]);
        var b = parseInt(rgb[2]);
        var hex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        //return hex;
      this.colorsd.push(hex)
    },
    uploadFile(file) {
        //console.log("开始上传")
        console.log(file);
        //let md5 = this.handlePrepareUpload(file);
        //console.log(md5);
      },
    showModal(obj) {
      if (obj.img) {
        this.option.img = obj.img
      }
      //裁剪生成图片的质量
      if (obj.outputSize) {
        this.option.outputSize = obj.outputSize
      } else {
        this.option.outputSize = 1
      }
      //裁剪生成图片的格式
      if (obj.outputType) {
        this.option.outputType = obj.outputType
      } else {
        this.option.outputType = 'jpeg'
      }
      //裁剪框的大小信息
      if (obj.info) {
        this.option.info = obj.info
      } else {
        this.option.info = true
      }
      //图片是否允许滚轮缩放
      if (obj.canScale) {
        this.option.canScale = obj.canScale
      } else {
        this.option.canScale = false
      }
      //是否默认生成截图框
      if (obj.autoCrop) {
        this.option.autoCrop = obj.autoCrop
      } else {
        this.option.autoCrop = true
      }
      //默认生成截图框宽度
      if (obj.autoCropWidth) {
        this.option.autoCropWidth = obj.autoCropWidth
      } else {
        this.option.autoCropWidth = 375
      }
      //默认生成截图框高度
      if (obj.autoCropHeight) {
        this.option.autoCropHeight = obj.autoCropHeight
      } else {
        this.option.autoCropHeight = 245
      }
      //固定截图框大小 不允许改变
      if (obj.fixedBox) {
        this.option.fixedBox = obj.fixedBox
      } else {
        this.option.fixedBox = false
      }
      //是否开启截图框宽高固定比例
      if (obj.fixed) {
        this.option.fixed = obj.fixed
      } else {
        this.option.fixed = true
      }
      //截图框的宽高比例
      if (obj.fixedNumber) {
        this.option.fixedNumber = obj.fixedNumber
      } else {
        this.option.fixedNumber = [this.option.autoCropWidth, this.option.autoCropHeight]
      }
      //上传图片是否可以移动
      if (obj.canMove) {
        this.option.canMove = obj.canMove
      } else {
        this.option.canMove = true
      }
      //截图框能否拖动
      if (obj.canMoveBox) {
        this.option.canMoveBox = obj.canMoveBox
      } else {
        this.option.canMoveBox = true
      }
      //上传图片按照原始比例渲染
      if (obj.original) {
        this.option.original = obj.original
      } else {
        this.option.original = false
      }
      //截图框是否被限制在图片里面
      if (obj.centerBox) {
        this.option.centerBox = obj.centerBox
      } else {
        this.option.centerBox = true
      }
      //true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      if (obj.infoTrue) {
        this.option.infoTrue = obj.infoTrue
      } else {
        this.option.infoTrue = true
      }
      //是否输出原图比例的截图
      if (obj.full) {
        this.option.full = obj.full
      } else {
        this.option.full = true
      }
      //图片根据截图框输出比例倍数
      if (obj.enlarge) {
        this.option.enlarge = obj.enlarge
      } else {
        this.option.enlarge = '1'
      }
      //图片默认渲染方式
      if (obj.mode) {
        this.option.mode = obj.mode
      } else {
        this.option.mode = 'contain'
      }
      if (obj.success) {
        this.success = obj.success
      } else {
        this.success = () => {}
      }
      this.dialogVisiblex = true
    },
    finish() {
      // 获取截图的数据
      let that = this
      this.$refs.cropper.getCropBlob(data => {
        that.unimgurl = data
        that.confirm()
      })
    },
    confirm() {
      this.success({
        img: this.unimgurl
      })
      this.dialogVisiblex = false
     this.imageUrl = window.URL.createObjectURL(this.unimgurl);
     this.getColor();
     
    },
    cancel() {
      this.dialogVisiblex = false
    }
  }
}
</script>
<style >

.el-checkbox__input .el-checkbox__inner {
    border-radius: 0;
  }
.file-image {
  width: 320px;
  height: 320px;
  font-size: 14px;
  border: 1px solid #cccccc;
  margin: 15px 0;
}

/* 截图 */
/* .cropper-content {} */
.cropper {
  width: 960px;
  height: 606px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  
  .demo-input-size {
    display: flex;
  }
  
  .inline-input {
    width: 40%;
  }
  .inline-input2 {
    width: 70%;
  }
  .hideTxt {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
  }
  .el-table {
    width: 99.9%;
  }

  .show {
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.show>li {
  width: 160px;
  height: 30px;
  margin: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
