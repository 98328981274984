<template>
  <div>
    <div v-if="imageUrl == ''">
      <el-divider content-position="center"
        >网络链接和本地上传二选一</el-divider
      >
      <el-input v-model="imageUrlTemp" style="width: 50%" placeholder="请输入内容">
        <el-button
          @click="uploadurlTemp"
          slot="append"
          icon="el-icon-upload2"
        ></el-button>
      </el-input>

      <el-upload
        ref="upload1"
        action=""
        class="avatar-uploader"
        :before-upload="beforeUpload"
        :http-request="uploadFile"
        accept=".png,.jpg,.jpeg"
        :multiple="false"
        :limit="10"
        :show-file-list="false"
        :auto-upload="true"
        
      >
        <img v-if="imageUrl" id="background" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"> </i>
      </el-upload>
    </div>

    <div class="pxclass">
     
    </div>

    <div v-if="imageUrl !=''" class="cropper-content">
      <div slot="footer" class="dialog-footer">
        <el-input
        v-model="option.autoCropWidth"
        style="width: 70px"
        max="1280"
        placeholder="像素宽"
      ></el-input>
      <el-input
        v-model="option.autoCropHeight"
        style="width: 70px"
        placeholder="像素高"
        max="1500"
      ></el-input>
      <el-button type="primary" @click="finish(1)">复制链接</el-button>
      <el-button type="primary" @click="finish(0)" :loading="loading"
        >下载</el-button>
        <!-- <el-button type="primary" @click="refreshCrop" :loading="loading"
        >重置</el-button> -->
    </div>
      <div class="cropper" style="text-align: center">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :output-size="option.size"
          :output-type="option.outputType"
          :info="true"
          :full="option.full"
          :fixed="fixed"
          :fixed-number="fixedNumber"
          :can-move="option.canMove"
          :can-move-box="option.canMoveBox"
          :fixed-box="option.fixedBox"
          :original="option.original"
          :auto-crop="option.autoCrop"
          :auto-crop-width="option.autoCropWidth"
          :auto-crop-height="option.autoCropHeight"
          :center-box="option.centerBox"
          :infoTrue="false"
          :enlarge="1"
          :high="option.high"
          @realTime="realTime"
          mode="contain"
          :max-img-size="option.max"
         
         
        ></vue-cropper>
      </div>
    </div>
    
  </div>
</template>
  
  
  <script >
import ColorThief from "colorthief";

import ajax from "../../common/js/axios.js";

import SparkMD5 from "spark-md5";
import axios from "axios";
import { Loading } from "element-ui";

export default {
  components: {},
  props: {},
  data() {
    return {
      imageUrlTemp:'',
      coverFile:'',
      witdhpx: "",
      heightpx: "",
      colorsd: [],
      showLoading: false,
      imageUrl: "",
      dialogVisiblex: false,
      loading: false,
      model: false,
        modelSrc: '',
        crap: false,
        previews: {},      
        option: {
          img: '',
          size: 1,
          full: false,
          outputType: 'jpeg',
          canMove: true,
          fixedBox: false,
          original: false,
          canMoveBox: false,
          autoCrop: true,
          // 只有自动截图开启 宽度高度才生效  100% auto
          autoCropWidth: 295,
          autoCropHeight: 413,
          centerBox: false,
          high: false,
          max: 99999
        },
        show: true,
        fixed: false,
        fixedNumber: [1, 1],
      unimgurl: "",
      success: () => {}, // 回调方法
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  activated() {},
  methods: {
    realTime:function(data){
    //    this.option.autoCropWidth=data.w;
    //    this.option.autoCropHeight=data.h;

    },
    getLink:function(){

    },
   
    uploadurl: function () {
      if (this.imageUrl == "") {
        this.$notify({
          title: "消息",
          message: "先上传图片在识别哦",
          type: "warning",
        });
        return;
      }

      this.option.img = this.imageUrl;
      this.dialogVisiblex = true;
    },
    uploadurlTemp: function () {
      if (this.imageUrlTemp == "") {
        this.$notify({
          title: "消息",
          message: "先上传图片在识别哦",
          type: "warning",
        });
        return;
      }

      this.option.img = this.imageUrlTemp;
      this.imageUrl = this.imageUrlTemp;
    },
    uploadFiles: function (md5, file) {
      this.md5 = md5;
      return new Promise(function (resolve, reject) {
        ajax.get("preImage?md5=" + md5 + "&scene=5", {}).then((response) => {
          console.log(response);
          if (response.code == 201) {
            resolve(response.meta);
            return response.meta;
          }
          if (response.code == -45) {
            reject(response);
            return response;
          }
          let responData = response.meta.bean;
          //  var form = {};
          //
          // form.policy = responData.policy;
          // form.OSSAccessKeyId = responData.accessid;
          // form.signature = responData.signature;
          // form.success_action_status = "200";
          // form.key = responData.ossKey;
          // form.fileKey = responData.fileKey;
          // form.callback = responData.base64CallbackBody;

          let formData1 = new window.FormData();

          formData1.append("key", responData.ossKey);
          formData1.append("policy", responData.policy);
          formData1.append("OSSAccessKeyId", responData.accessid);
          formData1.append("success_action_status", "200");
          formData1.append("callback", responData.base64CallbackBody);
          formData1.append("signature", responData.signature);
          formData1.append("file", file);
          axios({
            url: "https://" + responData.host,
            method: "post",
            data: formData1,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {
            console.log("res.." + JSON.stringify(res));
            if (res.data.Status == "OK") {
              let intervalID = setInterval(function () {
                console.log("轮训");
                ajax
                  .post("getImageStatus", {
                    md5: md5,
                  })
                  .then((response) => {
                    console.log("res..." + JSON.stringify(response));
                    if (response.code == 200) {
                      clearInterval(intervalID);
                      resolve(response.meta);
                      return response.meta;
                    } else if (response.code == -45) {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    } else if (response.code == 203) {
                      //clearInterval(intervalID)
                      //reject(response);
                      //return response;
                      console.log("kjk");
                      /* uni.showToast({
                                                                      title: '检测到上传文件共'+response.meta.pages+"页",请您耐心等待,
                                                                      icon: 'none'
                                                                  }); */
                      return;
                    } else if (response.code == 205) {
                      return;
                    } else {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    }
                  })
                  .catch((error) => {
                    clearInterval(intervalID);
                    reject(error);
                    return error;
                  });
              }, 2000);
            } else {
              console.log("oss出错。。。");
              reject(res);
              return res;
            }
          });
        });
      });
    },
    
    beforeUpload: function (file) {
      let _this = this;
      this.showLoading = true;
      const size = file.size / 1024 / 1024;
      if (size > 10) {
        this.$notify.info({
          title: "提示",
          message: "文件不能超过10m",
        });
        return;
      }
      let options = {};
      options.text = "处理中...请耐心等待~";
      let loadingInstance = Loading.service(options);
      this.handlePrepareUpload(file).then((res) => {
        this.uploadFiles(res, file)
          .then((res) => {
            //loadingInstance.close();
            console.log(res);
            //this.listResult.push(res);
            _this.showLoading = false;
            loadingInstance.close();
            _this.imageUrl = res.url;
            if(_this.coverFile != ''){
                _this.copy(_this.imageUrl);
            }else{
              _this.uploadurl();
            }
            
          
          })
          .catch((err) => {
            console.log(err);
            this.showLoading = false;
            loadingInstance.close();
            this.$notify({
              title: "消息",
              message: "转换错误，请刷新页面重试",
              type: "warning",
            });
          });
      });
    },
    handlePrepareUpload: function (file) {
      return new Promise(function (resolve) {
        const fileSize = file.size; // 文件大小
        const chunkSize = 1024 * 1024 * 10; // 切片的大小
        const chunks = Math.ceil(fileSize / chunkSize); // 获取切片个数
        const fileReader = new FileReader();
        const spark = new SparkMD5.ArrayBuffer();
        const bolbSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        let currentChunk = 0;

        fileReader.onload = (e) => {
          const res = e.target.result;
          spark.append(res);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
            console.log(
              `第${currentChunk}分片解析完成, 开始第${currentChunk + 1}分片解析`
            );
          } else {
            const md5 = spark.end() + ".jpg";

            //alert(md5)
            resolve(md5);
            return md5;
          }
        };

        const loadNext = () => {
          const start = currentChunk * chunkSize;
          const end =
            start + chunkSize > file.size ? file.size : start + chunkSize;
          fileReader.readAsArrayBuffer(bolbSlice.call(file, start, end));
        };
        loadNext();
      });
    },
    
    copy(txt) {
      const cInput = document.createElement("input");
      cInput.value = txt;
      document.body.appendChild(cInput);
      cInput.select(); //

      document.execCommand("Copy");

      this.$message({
        message: "复制成功  " + txt,
        type: "success",
        duration:1000
      });
    },
    transformHEXToGRB(rgb) {
      var r = parseInt(rgb[0]);
      var g = parseInt(rgb[1]);
      var b = parseInt(rgb[2]);
      var hex =
        "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      //return hex;
      this.colorsd.push(hex);
    },
    uploadFile(file) {
      //console.log("开始上传")
      console.log(file);
      //let md5 = this.handlePrepareUpload(file);
      //console.log(md5);
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名

      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/jpg"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    finish(type) {
      // 获取截图的数据
      let that = this;
      this.$refs.cropper.getCropBlob((data) => {
        that.unimgurl = data;
        if(type == 0){
          that.confirm();
        }else{
            that.imgUrlToFile();
        }
      
      });
    },
    confirm() {
     
      this.imageUrl = window.URL.createObjectURL(this.unimgurl);
      this.downloadIamge(this.imageUrl,"证件照.jpg");
      
    },
    imgUrlToFile() {
      var _this = this;
 
      //如果图片url是网络url，要加下一句代码
   
        _this.coverFile = _this.blobToFile(_this.unimgurl, "photo");
       
        _this.beforeUpload(_this.coverFile);
      
    },
    blobToFile(blob, filename) {
      // edge浏览器不支持new File对象，所以用以下方法兼容
      blob.lastModifiedDate = new Date();
      blob.name = filename;
      return blob;
    },
    cancel() {
      this.dialogVisiblex = false;
    },
  },
};
</script>
  <style >
.el-checkbox__input .el-checkbox__inner {
  border-radius: 0;
}
.file-image {
  width: 320px;
  height: 320px;
  font-size: 14px;
  border: 1px solid #cccccc;
  margin: 15px 0;
}

/* 截图 */
.cropper-content {
  margin: auto;
}
.cropper {
  width: 1280px;
  height: 1500px;
  margin: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.demo-input-size {
  display: flex;
}

.inline-input {
  width: 40%;
}
.inline-input2 {
  width: 70%;
}
.hideTxt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}
.el-table {
  width: 99.9%;
}

.show {
  width: auto;
  display: flex;
  flex-wrap: wrap;
}

.show > li {
  width: 160px;
  height: 30px;
  margin: 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pxclass {
}
</style>
  