<template>
  <div>
    <div class="about1">
      <h1 class="logoTitle">pdf转图片（image）</h1>
      <p class="titltext">在线、精准、不限量、免登录、免费</p>
    </div>

    <div v-if="showUpload" class="grid-content bg-purple">
      <el-upload
        drag
        action=""
        accept=".pdf"
        :before-upload="beforeUpload"
        :http-request="uploadFile"
        :style="{
          width: '68%',
          height: '90%',
          lineHeight: '70px',
          margin: 'auto',
        }"
      >
        <!-- <i class="el-icon-upload"></i> -->
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将pdf文件拖到此处，或<em>点击上传</em>
        </div>
      </el-upload>
    </div>

    <div v-if="!showUpload" class="grid-content bg-purple-light">
      <el-card
        class="box-card"
        :style="{ width: '68%', height: '90%', margin: 'auto' }"
        :body-style="{ height: '50vh' }"
      >
        <div slot="header" class="clearfix">
          <div class="resultheader">转换结果</div>

          <el-popover placement="bottom-end" width="200" trigger="hover">
            <el-image :src="pdf"></el-image>

            <el-button
              slot="reference"
              style="float: right; padding: 3px 0"
              type="text"
              >手机操作</el-button
            ></el-popover
          >

          <el-upload
            action=""
            :show-file-list="false"
            accept=".pdf"
            :before-upload="beforeUpload"
            :http-request="uploadFile"
          >
            <el-button style="float: right; padding: 3px 20px" type="text"
              >继续上传</el-button
            >
          </el-upload>
        </div>

        <el-row :gutter="24" v-for="item in listResult" :key="item.id">
          <el-col :span="14"
            ><div class="grid-content bg-purple">
              {{ item.fileName }}
            </div></el-col
          >
          <el-col :span="5"
            ><div class="grid-content bg-purple">
              <el-popover placement="right" width="300" trigger="click">
                <span class="bottom-affix">感谢你的支持</span>
                <el-image :src="starurl"></el-image>
                <el-button
                  slot="reference"
                  type="primary"
                  size="small"
                  icon="el-icon-sugar"
                  >赞赏</el-button
                >
              </el-popover>
            </div></el-col
          >

          <el-col :span="5"
            ><div class="grid-content bg-purple">
              <el-button
                type="primary"
                size="small"
                icon="el-icon-download"
                @click="downLoadWord(item.wordUrl)"
                >下载</el-button
              >
            </div></el-col
          >
        </el-row>
      </el-card>
    </div>

    <div class="maincontent">
      <h1 class="fonttitle">常见问题</h1>
      <!-- <div> -->
      <div class="question">
        <div>
          <div class="ask">
            <span class="qtit">问</span>
            <h1>为什么转出来的是乱码？</h1>
          </div>
          <div class="answer">
            <span class="qtit">答</span>
            <p class="ans">
              此种情况需要人工处理，可以联系~~<a
                target="_blank"
                href="http://wpa.qq.com/msgrd?v=3&uin=858299224&site=qq&menu=yes"
                ><img
                  border="0"
                  src="http://wpa.qq.com/pa?p=2:858299224:51"
                  alt="人工pdf转image"
                  title="人工pdf转image"
              /></a>
            </p>
          </div>
        </div>
      </div>

      <div class="question">
        <div>
          <div class="ask">
            <span class="qtit">问</span>
            <h1>提示转换错误怎么办？</h1>
          </div>
          <div class="answer">
            <span class="qtit">答</span>
            <p>
              pdf转图片，由于编码格式不同，也许我们的测试并不能涵盖各种情况，错误类型也各种各样，您可以通过人工客服反馈给我们，我们及时改进<br />
            </p>
            <p class="ans">如果有更多问题请联系人工客服。</p>
          </div>
        </div>
      </div>

      <div class="question">
        <div>
          <div class="ask">
            <span class="qtit">问</span>
            <h1>可以生成长图吗？</h1>
          </div>
          <div class="answer">
            <span class="qtit">答</span>
            <p>
              可以！如果你想把所有图片转成一个长图，我们非常欢迎你把次需求反馈给我们，通过上方的客服入口。<br />
            </p>
          </div>
        </div>
      </div>
    </div>

    <a-affix
      v-if="!hadStarAffix"
      :offset-bottom="20"
      :style="{ position: 'fixed', right: '20px', bottom: '20px' }"
    >
      <!-- <img class="bootImage" src="" /><br/> -->
      <el-image
        style="width: 140px; height: 140px"
        :src="src"
        :preview-src-list="srcList"
      >
      </el-image
      ><br />

      <span class="bottom-affix">解锁更多神器</span>
    </a-affix>

    <el-dialog
      title="免费不易-关注助力"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-image
        style="width: 160px; height: 160px; margin: auto; display: flex"
        :src="src"
      >
      </el-image>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">狠心离开</el-button>
        <el-button type="primary" @click="hadStar">我已关注</el-button>
      </span>
    </el-dialog>

    <!-- <affix type="bottom"  :offset="50" >
    
     
  </affix> -->
    <div>
      <el-link
        target="_blank"
        style="margin-top: 50px"
        href="https://beian.miit.gov.cn"
        type="info"
        >京ICP备 2020038026号</el-link
      >
      <!-- <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=858299224&site=qq&menu=yes"><img border="0" src="http://wpa.qq.com/pa?p=2:858299224:51" alt="联系我们" title="联系我们"/></a> -->
    </div>
  </div>
</template>

<script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>

<script>
import ajax from "../../common/js/axios.js";
//import ajaxOSS from "../common/js/axiososs.js";
import SparkMD5 from "spark-md5";
import axios from "axios";
import { Loading } from "element-ui";
//import affix from 'easy-affix'
import "../../common/css/question.css";

export default {
  metaInfo: {
    title: "图鲁班-pdf转图片",
    meta: [
      {
        name: "keywords",
        content: "免费pdf转图片,pdf转image,pdf转图片在线-100%免费,图鲁班",
      },
      {
        name: "description",
        content:
          "图鲁班|免费的pdf转图片(image)在线工具|免登录、免下载软件、免费、格式无差别转换、转换效果好、页面简约易操作",
      },
    ],
  },
  data() {
    return {
      showUpload: true,
      showloading: false,
      activeNames: ["1", "2", "3"],
      hadStarAffix: localStorage.hasStar == 1,
      centerDialogVisible: false,
      startTime: [],
      src:
        "https://www.yaoguai-oss-pub.cn/image/image_cache/2021/04/15/5c4d4f14-ff40-4c1b-b839-5f2969abf69c",
      starurl:
        "https://www.yaoguai-oss-pub.cn/image/image_cache/2021/04/16/99a67817-cdab-4753-9778-4f7ee1f52f25",
      srcList: [
        "https://www.yaoguai-oss-pub.cn/image/image_cache/2021/04/15/5c4d4f14-ff40-4c1b-b839-5f2969abf69c",
      ],
      pdf:
        "https://www.wenba123.com/article_share_image/wx/2021/04/16/1618560018443",
      ocr:
        "https://www.wenba123.com/article_share_image/wx/2021/04/16/1618561768389",

      anime: "",
      listResult: [],
      items: [
        {
          type: "",
          label: "证件照制作",
          id: 1,
          src:
            "https://www.wenba123.com/article_share_image/wx/2021/04/16/1618562716870",
        },
        {
          type: "success",
          label: "图片加水印",
          id: 2,
          src:
            "https://www.wenba123.com/article_share_image/wx/2021/04/16/1618568566844",
        },
        {
          type: "info",
          label: "人物动漫化",
          id: 3,
          src:
            "https://www.wenba123.com/article_share_image/wx/2021/04/16/1618564329462",
        },
      ],
    };
  },
  onload() {},
  methods: {
    change() {},
    uploadFile() {
      //console.log("开始上传")
      //console.log(file);
      //let md5 = this.handlePrepareUpload(file);
      //console.log(md5);
    },
    hadStar() {
      this.centerDialogVisible = false;
      localStorage.hasStar = 1;
    },
    preWord: function (wordUrl) {
      let url = "https://view.officeapps.live.com/op/view.aspx?src=" + wordUrl;

      window.open(url, "_blank");
    },
    downLoadWord: function (wordUrl) {
      console.log("...00 " + localStorage.hasStar == 0);
      console.log("..." + localStorage.hasStar);
      window.open(wordUrl);
      if (localStorage.hasStar != 1) {
        this.centerDialogVisible = true;
      }
    },
    beforeUpload: function (file) {
      const size = file.size / 1024 / 1024;
      if (size > 15.5) {
        this.$notify.info({
          title: "提示",
          message: "文件不能超过15.5m",
        });
        return;
      }
      let options = {};
      options.text = "pdf转图片中...,请你耐心等待";
      let loadingInstance = Loading.service(options);
      this.handlePrepareUpload(file).then((res) => {
        this.uploadFiles(res, file.name, file)
          .then((res) => {
            loadingInstance.close();
            console.log(res);
            this.listResult.push(res);
            this.showUpload = false;
            this.$notify({
              title: "成功",
              message: "pdf转word已完成",
              type: "success",
            });
          })
          .catch((err) => {
            loadingInstance.close();
            this.$notify({
              title: "消息",
              message: "转换错误，请刷新页面重试",
              type: "warning",
            });
          });
      });
    },
    uploadFiles: function (md5, fileName, file) {
      return new Promise(function (resolve, reject) {
        ajax
          .get(
            "pdf/preFile?md5=" +
              md5 +
              "&fileName=" +
              fileName +
              "&convertType=1",
            {}
          )
          .then((response) => {
            console.log(response);
            if (response.code == 201) {
              resolve(response.meta);
              return response.meta;
            }
            if (response.code == -45) {
              reject(response);
              return response;
            }
            let responData = response.meta.bean;
            //  var form = {};
            //
            // form.policy = responData.policy;
            // form.OSSAccessKeyId = responData.accessid;
            // form.signature = responData.signature;
            // form.success_action_status = "200";
            // form.key = responData.ossKey;
            // form.fileKey = responData.fileKey;
            // form.callback = responData.base64CallbackBody;

            let formData1 = new window.FormData();

            formData1.append("key", responData.ossKey);
            formData1.append("policy", responData.policy);
            formData1.append("OSSAccessKeyId", responData.accessid);
            formData1.append("success_action_status", "200");
            formData1.append("callback", responData.base64CallbackBody);
            formData1.append("signature", responData.signature);
            formData1.append("file", file);
            axios({
              url: "https://" + responData.host,
              method: "post",
              data: formData1,
              headers: { "Content-Type": "multipart/form-data" },
            }).then((res) => {
              console.log("res.." + JSON.stringify(res));
              if (res.data.Status == "OK") {
                let intervalID = setInterval(function () {
                  console.log("轮训");
                  ajax
                    .post("pdf/getPdfResult", {
                      md5: md5,
                      convertType: 1,
                    })
                    .then((response) => {
                      console.log("res..." + JSON.stringify(response));
                      if (response.code == 200) {
                        clearInterval(intervalID);
                        resolve(response.meta);
                        return response.meta;
                      } else if (response.code == -45) {
                        clearInterval(intervalID);
                        reject(response);
                        return response;
                      } else if (response.code == 203) {
                        //clearInterval(intervalID)
                        //reject(response);
                        //return response;
                        console.log("kjk");
                        /* uni.showToast({
																	title: '检测到上传文件共'+response.meta.pages+"页",请您耐心等待,
																	icon: 'none'
																}); */
                        return;
                      } else if (response.code == 205) {
                        return;
                      } else {
                        clearInterval(intervalID);
                        reject(response);
                        return response;
                      }
                    })
                    .catch((error) => {
                      clearInterval(intervalID);
                      reject(error);
                      return error;
                    });
                }, 2000);
              } else {
                console.log("oss出错。。。");
                reject(res);
                return res;
              }
            });
          });
      });
    },

    handlePrepareUpload: function (file) {
      return new Promise(function (resolve) {
        const fileSize = file.size; // 文件大小
        const chunkSize = 1024 * 1024 * 10; // 切片的大小
        const chunks = Math.ceil(fileSize / chunkSize); // 获取切片个数
        const fileReader = new FileReader();
        const spark = new SparkMD5.ArrayBuffer();
        const bolbSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        let currentChunk = 0;

        fileReader.onload = (e) => {
          const res = e.target.result;
          spark.append(res);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
            console.log(
              `第${currentChunk}分片解析完成, 开始第${currentChunk + 1}分片解析`
            );
          } else {
            const md5 = spark.end();

            //alert(md5)
            resolve(md5);
            return md5;
          }
        };

        const loadNext = () => {
          const start = currentChunk * chunkSize;
          const end =
            start + chunkSize > file.size ? file.size : start + chunkSize;
          fileReader.readAsArrayBuffer(bolbSlice.call(file, start, end));
        };
        loadNext();
      });
    },
  },
};
</script>


<style scoped>

.logoTitle {
  font-size: 30px;
}
.el-row {
  margin-bottom: 20px;
}
.bottom-affix {
  padding-right: 20px;
  font-family: "Helvetica Neue";
}
.bootImage {
  width: 130px;
  height: 130px;
}

/deep/.el-upload-dragger {
  width: 100% !important;
  height: 50vh;
}
/deep/.el-upload {
  height: 100% !important;
  display: block;
}
/deep/.el-icon-upload {
  font-size: 150px;
  padding-top: 50px;
}
/deep/.el-upload__text {
  line-height: 0px !important;
  font-size: 20px;
}
.resultheader {
  float: left;
}
.about1 {
  padding-top: 10px;
}
.about {
  height: 50px;
}
.tag-group__title {
  padding: auto;
}
.tag-group {
  padding-top: 20px;
}
.el-tag {
  width: 100px;
}

.titltext {
  font-size: 20px;
}
</style>
