<template>
  <div>
      <el-divider content-position="center">网络链接和本地上传二选一</el-divider>
      <el-input v-model="imageUrl" style="width:50%" placeholder="请输入内容">
       
      </el-input>

    <el-upload
      ref="upload1"
      action=""
      class="avatar-uploader"
      :before-upload="beforeUpload"
      :http-request="uploadFile"
      accept=".png,.jpg,.jpeg"
      :multiple="false"
      :limit="10"
      :show-file-list="false"
      :auto-upload="true"
      v-loading="showLoading"
    >
      <img v-if="imageUrl && resultUrl=='' " :src="imageUrl" class="avatar" />
     
      <i v-if="imageUrl == ''" class="el-icon-plus avatar-uploader-icon"> </i>
    </el-upload>
    <div>
      <div class="showImage" v-if="resultUrl !=''">
        <el-image :preview-src-list="srcList"  :src="imageUrl" class="avatar" ></el-image >
        <el-image  :preview-src-list="srcList" :src="resultUrl" class="avatar"></el-image >
      </div>
     
        <!-- <div class="block" style="margin-top: 20px"> -->
         
    <div  v-if="imageUrl !=''">
      <el-radio-group @change="changeValue"  v-model="format">
      <el-radio-button label="jpg"></el-radio-button>
      <el-radio-button label="png"></el-radio-button>
      <el-radio-button label="webapp"></el-radio-button>
      <el-radio-button label="bmp"></el-radio-button>
      <el-radio-button label="tiff"></el-radio-button>
    </el-radio-group>
  <!-- </div> -->
</div>

<div v-if="resultUrl !=''">
     <el-button
        size="mini"
        type="primary"
        @click="copy(resultUrl)"
        icon="el-icon-document-copy"
        >复制链接</el-button
      >
     
    </div>
  
  </div>



  </div>
</template>


<!-- 引入css，该链接始终为最新版的资源 -->

<!-- 引入vue -->
<!-- 引入组件，该链接始终为最新版的资源 -->


<script>
import ajax from "../../common/js/axios.js";
//import ajaxOSS from "../common/js/axiososs.js";
import SparkMD5 from "spark-md5";
import axios from "axios";
import { Loading } from "element-ui";
//import affix from 'easy-affix'


export default {
  metaInfo: {
    title: "图鲁班-证件照换底色",
    meta: [
      {
        name: "keywords",
        content:
          "图鲁班,证件照换底色",
      },
      {
        name: "description",
        content:
          "图鲁班,证件照换底色，一键更换证件照底色",
      },
    ],
  },
  data() {
    return {
      format:{},
      imageInfo:{},
      compressConfig: {
          rate: 50,
        },
      number:45,
      colorUpdate: '#488CDC',
      predefineColors: [
        '#488CDC',
        '#FFFFFF',
        '#ff0000',
        '#80BCF4',
        
      ],
      srcList:[],
      radio3: '上海',
      showkbInfo:{},
      checkDialogVisible:false,
      checkUrl:"",
      detail:{},
      showPhotoDetail:false,
      imageUrl: "",
      radio: 3,
      restaurants: [],
      state1: "",
      state2: "",
      tableData: [],
      orderTypeValue:'',

      input1: "",
      input2: "",
      orderNum: "",
      userId: "",
      options: [
        {
          value: "blue",
          label: "蓝色",
        },
        {
          value: "white",
          label: "白色",
        },
        {
          value: "red",
          label: "红色",
        },
        {
          value: "#80BCF4",
          label: "浅蓝",
        },
      ],
      convertUrl:"",
      imageOriginUrl1:"",
      fileType:"0",
      addressType: [
        {
          value: "0",
          label: "可删除",
        },
        {
          value: "1",
          label: "不删除",
        }
        
      ],
      orderType: [
        {
          value: "0",
          label: "电子版",
        },
        {
          value: "5",
          label: "排版",
        },
        {
          value: "10",
          label: "换装",
        },
        {
          value: "15",
          label: "换装排版",
        },
      ],
      value: "",
      imageResultUrl: "",
      imageOriginUrl: "",
      email: "",
      color:"",
      minSize: "",
      maxSize: "",
      px: "",
      showLoading: false,
      making: false,

      dialogTableVisiblePay: false,
      dialogTableVisiblePrint: false,
      
      priceForm:{
         password:'',
         refusePrice:'',
        orderId:''
      },
      colorValueRgb:'',
      fillColor: "#3F91E4",
      textColor: "#ffffff",
      radio2: "蓝色",
      activeName: "first",
      centerDialogVisible: false,
      payCodeUrl: "",
      listResult: [],
      items: [],
      clothes: {},
      state: "",
      certificateType: [],
      clothesType: "",
      formatId: 1,
      colorCode: 2,
      photoId: 0,
      md5: "",
      loadingInstance: "",
      resultImage: "",
      formatName: "一寸",
      price: 3.9,
      showName: "一寸",
      showUnit: "",
      codeUrl: "",
      dialogFormVisible: false,
      dialogFormPrice: false,
      formLabelWidth: "120px",
      PENDING:[],
      PROCESSING:[],
      covertUrl:"",
      form: {
        tracingCode: "",
        deliverCode: "1",
        orderId: "",
      },
      colorValue:[],
      resultUrl:''
    };
  },
  created: function () {
    console.log("dddd");
    //this.restaurants = this.loadAll();
    this.colorValue = this.loadColor();
    //this.listComplaints();
  },
  methods: {
    changeValue:function(value){
      console.log("value.."+value);
    
      let _this = this;
      if(this.imageUrl == ''){
        this.$notify({
              title: "消息",
              message: "先上传图片在识别哦",
              type: "warning",
            });
        return;
      }


      let options = {};
      options.text = "处理中...请耐心等待~";
      let loadingInstance = Loading.service(options);
        let form = {};
        form.url = _this.imageUrl;
        form.format = value;
          ajax.post("zip/updateFormat", form).then((response) => {
           
            loadingInstance.close();
           console.log("ress..."+response);
            
          _this.resultUrl = response.meta.url;
          
           _this.srcList.push(_this.imageUrl);
          _this.srcList.push(_this.resultUrl);
          _this.imageInfo = response.meta;
        });



    },
    uploadurl:function(){

    },
    downloadIamge(imgsrc, name) {
    //下载图片地址和图片名

    var image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      var canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      var context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      var url = canvas.toDataURL("image/jpg"); //得到图片的base64编码数据

      var a = document.createElement("a"); // 生成一个a元素
      var event = new MouseEvent("click"); // 创建一个单击事件
      a.download = name || "photo"; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgsrc;
  },
    changeColor:function(value){

      if(this.md5 == '' && this.imageUrl == ''){
        this.$message({
          message: '还没上传图片',
          type: 'success'
        });
        return;
        
      }
      let options = {};
    options.text = "处理中...请耐心等待~";
    let loadingInstance = Loading.service(options);
      if(this.md5 == '' && this.imageUrl != ''){
        let _this = this;
      let form = {};
      form.url = _this.imageUrl;
      form.color = value;
        ajax.post("segment/updateBackGroundWeb", form).then((response) => {
         
          loadingInstance.close();
         console.log("ress..."+response);
          
        _this.resultUrl = response.meta.url;
         _this.srcList.push(_this.imageUrl);
        _this.srcList.push(_this.resultUrl);
        
      });
          return;
      }

      let _this = this;
      let form = {};
      form.md5 = _this.md5;
      form.color = value;
        ajax.post("segment/updateBackGround", form).then((response) => {
          loadingInstance.close();
         
         console.log("ress..."+response);
          
        _this.resultUrl = response.meta.url;
        _this.srcList.push(_this.imageUrl);
        _this.srcList.push(_this.resultUrl);
        
      });
    },
    
    checkImage:function(){
        let _this = this;
      if (_this.checkUrl == "" || _this.checkUrl == undefined) {

        this.$message({
          message: '图片链接没有输入',
          type: 'success'
        });
        return;
      }
      let form = {};
      form.url = _this.checkUrl;
        ajax.post("/getImageInfo", form).then((response) => {
         
          _this.showkbInfo = response.meta;
          
         _this.checkDialogVisible = true;
        
      });
    },
    listComplaints:function(){
        let form = {};
        ajax.post("order/listComplaints", form).then((response) => {
        //_this.tableData = ;
        this.PENDING=response.meta.PENDING;
        this.PROCESSING = response.meta.PROCESSING;

      
      });
      },

    // querySearch(queryString, cb) {
    //   if(queryString == ''){
    //     return;
    //   }
    //     ajax.post("certificate/searchCertificateV2", {
    //       keyword:queryString
    //     }).then((response) => {
    //      console.log(response);

    //      cb(response.meta.result);

    // });

    // },
    showPhotoDetails(row){
      //debugger;
      this.detail = row;

      this.showPhotoDetail = true;

    },

    load() {},

    change() {},

    uploadFile(file) {
      //console.log("开始上传")
      console.log(file);
      //let md5 = this.handlePrepareUpload(file);
      //console.log(md5);
    },

    downLoadWord: function (wordUrl) {
      console.log("...00 " + localStorage.hasStar == 0);
      console.log("..." + localStorage.hasStar);
      window.open(wordUrl);
      if (localStorage.hasStar != 1) {
        this.centerDialogVisible = true;
      }
    },
    beforeUpload: function (file) {
      this.showLoading = true;
      const size = file.size / 1024 / 1024/3;
      if (size > 30) {
        this.$notify.info({
          title: "提示",
          message: "文件不能超过30m",
        });
        return;
      }

      this.handlePrepareUpload(file).then((res) => {
        this.uploadFiles(res, file)
          .then((res) => {
            //loadingInstance.close();
            console.log(res);
            //this.listResult.push(res);
            this.showLoading = false;
            this.imageUrl = res.url;
          })
          .catch((err) => {
            console.log(err);
            this.showLoading = false;
            this.$notify({
              title: "消息",
              message: "转换错误，请刷新页面重试",
              type: "warning",
            });
          });
      });
    },
    getCertificatePhoto: function () {
      let _this = this;
      console.log("md5..." + _this.md5);
      if (_this.md5 == "" || _this.md5 == undefined) {
        return;
      }
      let options = {};
      options.text = "证件照制作中...请你耐心等待";
      this.loadingInstance = Loading.service(options);
      console.log("type..." + _this.clothesType);
      ajax
        .post("certificate/makePhoto", {
          md5: _this.md5,
          colorCode: _this.colorCode,
          formatId: _this.formatId,
          clothesType: _this.clothesType,
        })
        .then((res) => {
          this.loadingInstance.close();

          if (res.code != 200) {
            this.$notify.info({
              title: "提示",
              message: "检测图片不和规范，在换张图片试试哦",
            });
          } else {
            document.querySelector(".photo").scrollIntoView(true);
            //this.resultImage=res.
            this.photoId = res.meta.photoId;
            this.resultImage = res.meta.photoUrl;
            console.log("image..." + this.resultImage);
            console.log(this.photoId);
            this.$notify({
              title: "成功",
              message: "证件照制作完成",
              type: "success",
            });
            // _this.showAddImage = false;
            // _this.imageUrl = res.data.meta.photoUrl;
            // _this.photoId = res.data.meta.photoId;
          }
        });
    },
    uploadFiles: function (md5, file) {
      this.md5 = md5;
      return new Promise(function (resolve, reject) {
        ajax.post("preImage?md5=" + md5+"&scene=5" , {
         
        }).then((response) => {
          console.log(response);
          if (response.code == 201) {
            resolve(response.meta);
            return response.meta;
          }
          if (response.code == -45) {
            reject(response);
            return response;
          }
          let responData = response.meta.bean;
          //  var form = {};
          //
          // form.policy = responData.policy;
          // form.OSSAccessKeyId = responData.accessid;
          // form.signature = responData.signature;
          // form.success_action_status = "200";
          // form.key = responData.ossKey;
          // form.fileKey = responData.fileKey;
          // form.callback = responData.base64CallbackBody;

          let formData1 = new window.FormData();

          formData1.append("key", responData.ossKey);
          formData1.append("policy", responData.policy);
          formData1.append("OSSAccessKeyId", responData.accessid);
          formData1.append("success_action_status", "200");
          formData1.append("callback", responData.base64CallbackBody);
          formData1.append("signature", responData.signature);
          formData1.append("file", file);
          axios({
            url: "https://" + responData.host,
            method: "post",
            data: formData1,
            headers: { "Content-Type": "multipart/form-data" },
          }).then((res) => {
            console.log("res.." + JSON.stringify(res));
            if (res.data.Status == "OK") {
              let intervalID = setInterval(function () {
                console.log("轮训");
                ajax
                  .post("getImageStatus", {
                    md5: md5,
                  })
                  .then((response) => {
                    console.log("res..." + JSON.stringify(response));
                    if (response.code == 200) {
                      clearInterval(intervalID);
                      resolve(response.meta);
                      return response.meta;
                    } else if (response.code == -45) {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    } else if (response.code == 203) {
                      //clearInterval(intervalID)
                      //reject(response);
                      //return response;
                      console.log("kjk");
                      /* uni.showToast({
                                                                    title: '检测到上传文件共'+response.meta.pages+"页",请您耐心等待,
                                                                    icon: 'none'
                                                                }); */
                      return;
                    } else if (response.code == 205) {
                      return;
                    } else {
                      clearInterval(intervalID);
                      reject(response);
                      return response;
                    }
                  })
                  .catch((error) => {
                    clearInterval(intervalID);
                    reject(error);
                    return error;
                  });
              }, 2000);
            } else {
              console.log("oss出错。。。");
              reject(res);
              return res;
            }
          });
        });
      });
    },

    handlePrepareUpload: function (file) {
      return new Promise(function (resolve) {
        const fileSize = file.size; // 文件大小
        const chunkSize = 1024 * 1024 * 10; // 切片的大小
        const chunks = Math.ceil(fileSize / chunkSize); // 获取切片个数
        const fileReader = new FileReader();
        const spark = new SparkMD5.ArrayBuffer();
        const bolbSlice =
          File.prototype.slice ||
          File.prototype.mozSlice ||
          File.prototype.webkitSlice;
        let currentChunk = 0;

        fileReader.onload = (e) => {
          const res = e.target.result;
          spark.append(res);
          currentChunk++;
          if (currentChunk < chunks) {
            loadNext();
            console.log(
              `第${currentChunk}分片解析完成, 开始第${currentChunk + 1}分片解析`
            );
          } else {
            const md5 = spark.end();

            //alert(md5)
            resolve(md5);
            return md5;
          }
        };

        const loadNext = () => {
          const start = currentChunk * chunkSize;
          const end =
            start + chunkSize > file.size ? file.size : start + chunkSize;
          fileReader.readAsArrayBuffer(bolbSlice.call(file, start, end));
        };
        loadNext();
      });
    },

    querySearch1(queryString, cb) {
      var restaurants = this.colorValue;
      var results = queryString
        ? restaurants.filter(this.createFilter1(queryString))
        : restaurants;
      
      cb(results);
    },
    createFilter1(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadColor(){
      return [
        { value: "蓝色", address: "#488CDC" },
        {
          value: "白色",
          address: "#ffffff",
        },
        {
          value: "红色",
          address: "#FF0404",
        },
        { value: "浅蓝色", address: "#80BCF4" }
        
      ];
    },
    loadAll() {
      return [
        { value: "358x441", address: "358x441" },
        {
          value: "295x413",
          address: "295x413",
        },
        {
          value: "390x567",
          address: "390x567",
        },
        { value: "390x579", address: "390x579" },
        {
          value: "390x626",
          address: "390x626",
        },
        {
          value: "144x192",
          address: "144x192",
        },
        {
          value: "480x640",
          address: "480x640",
        },
        {
          value: "413x579",
          address: "413x579",
        },{
          value: "413x626",
          address: "413x626",
        },{
          value: "960x1280",
          address: "960x1280",
        },
      ];
    },
    handleSelect(item) {
      console.log(item);
    },
    handleSelect1(item) {
      //debugger;
      console.log(item);
      this.colorValueRgb = item.address;
    },
    showPrice(orderId,price) {
      this.dialogFormPrice = true;
       this.priceForm.refusePrice = price;
        this.priceForm.orderId = orderId;

    },

    handleClick() {
      let _this = this;
        if (_this.priceForm.password == "" ||_this.priceForm. password == undefined) {
        this.$message({
          type: "success",
          message: "密码为空 ",
        });
        return;
      }
      if(_this.priceForm.refusePrice == "" ||_this.priceForm.refusePrice == undefined){
         this.$message({
          type: "success",
          message: "退款金额为空 ",
        });
        return;
      }

    
      let form = {};
      form.orderId =_this.priceForm.orderId;
      form.password = _this.priceForm.password;
       form.price = _this.priceForm.refusePrice;
     
      ajax.post("order/refundsOrder", form).then((response) => {
        //_this.tableData = ;
        if (response.code == -22) {
          this.$message({
            type: "fail",
            message: "验证码错误 ",
          });
          return;
        }

        this.$message({
          type: "success",
          message: "已经退款",
        });
      });
    },

    searchOrder() {
      let _this = this;
      // if (this.orderNum == "" && this.userId == "") {
      //   this.$message("输入订单号或者用户id");
      //   return;
      // }
      let form = {};
      form.orderNum = this.orderNum;
      form.userId = this.userId;
      form.orderTypeValue = this.orderTypeValue;
      ajax.post("order/listOrder", form).then((response) => {
        _this.tableData = response.meta;
      });
    },
    isNumber: function (param) {
      if (param == "") {
        return true;
      }

      var regPos = /^[0-9]+.?[0-9]*/; //判断是否是数字。

      if (regPos.test(param)) {
        return true;
      } else {
        return false;
      }
    },
    makePhotoDiy() {
      let _this = this;
      _this.making = true;
      
      let form = {};
      if (!this.isNumber(this.minSize) || !this.isNumber(this.maxSize)) {
        this.$message("大小为数字");
        _this.making = false;
        return;
      }
      if (this.imageOriginUrl == "" || this.colorValueRgb == "" || this.px == "") {
        this.$message("必填参数为空");
        _this.making = false;
        return;
      }
      form.px = this.px;
      form.minSize = this.minSize;
      form.maxSize = this.maxSize;
      form.imageOriginUrl = this.imageOriginUrl;
      form.color = this.colorValueRgb;

      ajax.post("certificate/makePhotoDiy", form).then((response) => {
        //_this.tableData = ;
        _this.imageResultUrl = response.meta.url;
        _this.making = false;
      });
    },

    copy(txt) {
      const cInput = document.createElement("input");
      cInput.value = txt;
      document.body.appendChild(cInput);
      cInput.select(); //

      document.execCommand("Copy");

      this.$message({
        message: "复制成功  "+txt,
        type: "success",
        duration:1000
      });
    },
    linkTxt(txt) {
      this.imageOriginUrl = txt;
    },
    addSize() {
      if (this.minSize == "") {
        return;
      }
      let mini = parseInt(this.minSize);
      if (this.maxSize == "") {
        this.maxSize = mini + 10;
      } else {
        this.maxSize = this.maxSize + 10;
      }
    },
    copyConvertUrl(){
      let _this = this;
      _this.copy(this.convertUrl);
            this.$message({
              type: "success",
              message: "制作链接已经复制到剪贴板 ",
            });
    },
    getImageUrl(){
      let _this = this;
      if (this.imageOriginUrl1 == "" || this.imageOriginUrl1 == "") {
        this.$message({
          type: "fail",
          message: "参数为空 ",
        });
        return;
      }
      let form = {};
      form.url = this.imageOriginUrl1;
      form.fileKey = this.fileType;
      ajax.post("/getImageUrl", form).then((response) => {
        _this.convertUrl = response.meta.resultUrl;
        _this.copyConvertUrl();
        this.$message({
          type: "fail",
          message: "成功 ",
        });
        return;
      });
    },
    sendEmail() {
      if (this.email == "" || this.imageOriginUrl == "") {
        this.$message({
          type: "fail",
          message: "参数为空 ",
        });
        return;
      }
      let form = {};
      form.email = this.email;
      form.url = this.imageOriginUrl;

      ajax.post("certificate/sendEmail", form).then((response) => {
        //_this.tableData = ;

        this.$message({
          type: "fail",
          message: "成功 ",
        });
        return;
      });
    },

    setDelivery(orderId) {
      this.dialogFormVisible = true;
      this.form.orderId = orderId;
    },
    submitDelivery() {
      this.dialogFormVisible = false;
      if (this.form.tracingCode == "") {
        this.$message({
          type: "success",
          message: "快递码为空 ",
        });
        return;
      }

      console.log("from.." + this.form);

      ajax.post("delivery/insertTracingV2", this.form).then((response) => {
        //_this.tableData = ;

        this.$message({
          type: "fail",
          message: "成功 ",
        });
        return;
      });
    },
    makeAgin(orderId, photoId) {
      let _this = this;
      this.$prompt("请输入密码", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (value == "" || value == undefined) {
            this.$message({
              type: "success",
              message: "密码为空 ",
            });
          }
          let form = {};
          form.photoId = photoId;
          form.orderId = orderId;
          form.passowrd = value;
          ajax.post("certificate/makeAgin", form).then((response) => {
            //_this.tableData = ;
            if (response.code == -22) {
              this.$message({
                type: "fail",
                message: "验证码错误 ",
              });
              return;
            }
            _this.imageResultUrl = response.meta.url;
            _this.copy(response.meta.url);
            this.$message({
              type: "success",
              message: "制作链接已经复制到剪贴板 ",
            });
          });
        })
        .catch(() => {});
    },
  },
};
</script>



<style scoped>
.block{
  display: flex;
}
.demonstration{

}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.demo-input-size {
  display: flex;
}

.inline-input {
  width: 40%;
}
.inline-input2 {
  width: 70%;
}
.hideTxt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
}
.el-table {
  width: 99.9%;
}
.showImage{
  display: flex;
  justify-content:center;
}
</style>
